import { ref } from 'vue';
import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
import isValidEmail from '@/utils/validations/isValidEmail';

const useRegisterByCode = () => {
  const router = useRouter();
  const store = useStore();

  const isLoading = ref(false);
  const messageForm = ref({
    message: '',
    messageType: 'success',
  });

  const userForm = ref({
    security_code: '',
    email: '',
    terms_and_conditions: false,
  });
  const resetValues = () => {
    messageForm.value.message = '';
    messageForm.value.messageType = '';
  };

  const resetFields = () => {
    userForm.value.security_code = '';
    userForm.value.email = '';
    userForm.value.terms_and_conditions = false;
  };

  const showMessage = (message, messageType) => {
    isLoading.value = false;
    messageForm.value.message = message;
    messageForm.value.messageType = messageType;
  };

  const registerByCodeUser = async () => {
    isLoading.value = true;
    resetValues();

    const acceptTerms = userForm.value.terms_and_conditions ? 1 : 0;

    if (!isValidEmail(userForm.value.email)) {
      showMessage('Ingrese un correo válido', 'error');
      return;
    } else if (acceptTerms === 0) {
      showMessage('Acepte los términos y condiciones', 'error');
      return;
    }

    const { success, message } = await store.dispatch('auth/registerByCode', {
      ...userForm.value,
      terms_and_conditions: acceptTerms,
    });

    const messageType = success ? 'success' : 'error';
    if (success) {
      localStorage.setItem('email', userForm.value.email);
      resetFields();
      resetValues();
      router.push({ name: 'email-confirmation' });
    } else if (!success) {
      showMessage(message, messageType);
    }
  };

  return {
    registerByCodeUser,
    isLoading,
    userForm,
    messageForm,
  };
};

export default useRegisterByCode;
