<template>
  <section class="form">
    <FeaturedColPartForm
      :linkText="$t('signUp.linkText')"
      :openModal="() => (isShow = !isShow)"
    />
    <article class="form-content form-info">
      <HeaderComponentPartForm
        :title="$t('signUp.title')"
        :description="$t('signUp.description')"
        route="login"
        :linkName="$t('signUp.linkName')"
      />
      <div class="buttons mt-2">
        <button
          id="btn-form-code"
          class="btn-option"
          :class="currentForm === 'form-code' ? 'selectedOption' : ''"
          @click="changeForm('form-code')"
        >
          {{ $t('signUp.buttons.codeButton') }}
        </button>
        <button
          class="btn-option"
          :class="currentForm === 'form-data' ? 'selectedOption' : ''"
          @click="changeForm('form-data')"
        >
          {{ $t('signUp.buttons.dataButton') }}
        </button>
      </div>
      <FormCode v-if="currentForm === 'form-code'" :show="policyShow" />
      <FormInfo v-if="currentForm === 'form-data'" />
      <div
        class="text-center mt-1 link-modal-mobile"
        @click="() => (isShow = !isShow)"
      >
        <span class="btn-link hidden-mobile link link-modal">
          {{ $t('signUp.linkText') }}
        </span>
      </div>
      <ModalLayout :isShow="isShow">
        <ModalContainer :close="showOrClose" typeModal="text">
          <HowRegister :close="showOrClose" />
        </ModalContainer>
      </ModalLayout>
    </article>
  </section>
</template>


<script>
import { ref } from 'vue';
import {
  FeaturedColPartForm,
  HeaderComponentPartForm,
} from '@/modules/auth/components/form-parts';

import FormCode from './forms/RegisterCodeForm';
import FormInfo from './forms/RegisterInfoForm';
import useModal from '@/composables/useModal';
import useRegister from '@/modules/auth/composables/register/useRegister';
import ModalLayout from '@/layouts/modals/ModalLayout';
import ModalContainer from '@/components/ModalContainer';
import HowRegister from '@/modules/auth/components/register/modals/HowRegister';

import { onBeforeMount } from 'vue';
import useAuthentication from '@/modules/auth/composables/authentication/useAuthentication';

export default {
  components: {
    FeaturedColPartForm,
    HeaderComponentPartForm,
    FormCode,
    FormInfo,
    ModalLayout,
    HowRegister,
  },
  setup() {
    onBeforeMount(() => {
      const { validateAuthentication } = useAuthentication();
      validateAuthentication('register');
    });

    const policyShow = ref(false);

    return {
      policyShow,
      ...useModal(),
      ...useRegister(),
      ModalLayout,
      ModalContainer,
    };
  },
};
</script>
