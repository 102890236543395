import { ref } from 'vue';

const useRegister = () => {
  const currentForm = ref('form-code');
  const changeForm = (form) => {
    currentForm.value = form;
  };
  return {
    changeForm,
    currentForm,
  };
};

export default useRegister;
