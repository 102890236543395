import { ref } from 'vue';
const useRegisterByCode = () => {
  let select = ref(true);
  let isShow = ref(false);
  let typeModal = ref("result");
  let type = ref("success");

  const showOrClose = () =>{
    isShow.value = !isShow.value
  }
      
  return {
    showOrClose,
    select,
    isShow,
    typeModal,
    type,
  }
};

export default useRegisterByCode;
