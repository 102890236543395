<template>
  <form class="body mt-2" @submit.prevent="registerByPersonalDataUser">
    <ModalLayout :isShow="isShow">
      <ModalContainer :close="showOrClose" typeModal="text" type_="no_modal">
        <PrivacyPolicy :close="showOrClose" />
      </ModalContainer>
    </ModalLayout>
    <div class="form-control">
      <label for="company_name">{{ $t('signUp.companyData.form.companyNameLabel') }}</label>
      <input
        type="text"
        name="company_name"
        :placeholder="$t('signUp.companyData.form.companyNamePlaceholder')"
        v-model="userForm.company_name"
        required
      />
    </div>

    <div class="form-control mt-1">
      <label for="name">{{ $t('signUp.companyData.form.apoderadoNameLabel') }}</label>
      <input
        type="text"
        name="name"
        :placeholder="$t('signUp.companyData.form.apoderadoNamePlaceholder')"
        v-model="userForm.name"
        required
      />
    </div>

    <div class="d-flex gap-0-5 form-control form-control-flex mt-1">
      <div class="form-control">
        <label for="company_ruc">{{ $t('signUp.companyData.form.rucLabel') }}</label>
        <input
          type="text"
          name="company_ruc"
          :placeholder="$t('signUp.companyData.form.rucPlaceholder')"
          v-model="userForm.ruc"
          required
        />
      </div>
      <div class="form-control">
        <label for="company_phone">{{ $t('signUp.companyData.form.companyPhoneLabel') }}</label>
        <input
          type="number"
          name="company_phone"
          :placeholder="$t('signUp.companyData.form.companyPhonePlaceholder')"
          v-model.number="userForm.cellphone"
        />
      </div>
    </div>

    <div class="form-control mt-1">
      <label for="email_address">{{ $t('signUp.companyData.form.emailLabel') }}</label>
      <input
        type="email"
        name="email_address"
        :placeholder="$t('signUp.companyData.form.emailPlaceholder')"
        v-model="userForm.email"
        required
      />
    </div>

    <div class="form-control mt-1">
      <label for="fiscal_address">{{ $t('signUp.companyData.form.fiscalAddressLabel') }}</label>
      <input
        type="text"
        name="fiscal_address"
        :placeholder="$t('signUp.companyData.form.fiscalAddressPlaceholder')"
        v-model="userForm.address"
      />
    </div>

    <div class="form-control form-control-check mt-1">
      <input
        type="checkbox"
        v-model="userForm.terms_and_conditions"
        name="terms"
      />
      <label for="terms" class="label-small">
        {{ $t('signUp.companyData.form.termsLabel') }}
        <a class="underline" @click="isShow = !isShow">{{ $t('signUp.companyData.form.privacyPolicyLink') }}</a>
      </label>
    </div>

    <LoadingComponent v-if="isLoading" />

    <MessageComponent
      v-if="!isLoading && messageForm.message"
      :message="messageForm.message"
      :messageType="messageForm.messageType"
      :classes="['text-center', 'mt-1', 'form-message']"
    />

    <div class="form-actions mt-2">
      <button
        v-if="isShowBtn"
        type="button"
        class="btn btn-primary mb-1"
        @click="changeForm"
      >
        {{ $t('signUp.companyData.form.acceptButton') }}
      </button>
      <button type="submit" class="btn btn-secondary">{{ $t('signUp.companyData.form.submitButton') }}</button>
    </div>
  </form>
</template>


<script>
import { LoadingComponent, MessageComponent } from '@/components';
import useRegisterByPersonalData from '@/modules/auth/composables/register/useRegisterByPersonalData';
import useRegister from '@/modules/auth/composables/register/useRegister';
import useModal from '@/composables/useModal';
import ModalLayout from '@/layouts/modals/ModalLayout';
import ModalContainer from '@/components/ModalContainer';
import PrivacyPolicy from '@/modules/auth/components/register/modals/PrivacyPolicy';
export default {
  props: {
    show: {
      type: Boolean,
      required: true,
    },
  },
  components: {
    LoadingComponent,
    MessageComponent,
    ModalContainer,
    ModalLayout,
    PrivacyPolicy,
  },
  setup() {
    const changeForm = () => {
      const btnOption = document.getElementById('btn-form-code');
      btnOption.click();
    };
    return {
      ...useRegisterByPersonalData(),
      ...useModal(),
      ...useRegister(),
      changeForm,
    };
  },
};
</script>

<style></style>
