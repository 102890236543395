<template>
  <div class="modal">
    <div class="modal-content modal-content-register modal-how">
      <div class="icon">
        <i class="fas fa-times text-primary close-modal" @click="close"></i>
      </div>
      <div class="how-modal">
        <div class="header">
          <h1 class="title mb-1">{{ $t('signUp.howto.title') }}</h1>
        </div>
        <div class="body scroll text-center p-0">
          <div class="form-content p-0">
            <div class="buttons py-md-1 pb-md-2">
              <button
                class="btn-custom cursor-auto"
                :class="selectOption ? 'activated' : ''"
                @click="selectOption = true"
              >
                {{ $t('signUp.howto.codeButton') }}
              </button>
              <button
                class="btn-custom cursor-auto"
                :class="!selectOption ? 'activated' : ''"
              >
                {{ $t('signUp.howto.dataButton') }}
              </button>
            </div>
          </div>
          <div class="tutorial mt-1">
            <p>
              {{ $t('signUp.howto.tutorialText1') }}
            </p>
            <br />
            <p>
              {{ $t('signUp.howto.tutorialText2') }}
            </p>
            <button class="btn btn-secondary mt-2 close-modal" @click="close">
              {{ $t('signUp.howto.understandButton') }}
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<script>
import { ref } from "vue";
export default {
  props: {
    close: {
      type: Function,
    },
  },
  setup() {
    const selectOption = ref(true);

    return {
      selectOption,
    };
  },
};
</script>